import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import CivilEngineering from '../components/CivilEngineering';
import BuildingsConstruction from '../components/BuildingsConstruction'
const Services = () => {

useEffect(()=>{
document.title = 'Civil Engineering | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Civil Engineering</h3>
                      {/* <p>
                        Expertise covered under civil and structural engineering construction includes, Geotechnical investigations, Design and construction, Electro-mechanical works. Specific
areas where BPECL has extensive experiences are presented below:
                      </p> */}
                      <ul>
                        
 
 <li>Construction of Bridges (composite and reinforced concrete) and associated ancillary works</li>
 <li>Geotechnical investigations.</li>
 <li>Design and construction.</li>
 <li>Electro-mechanical works.</li>
 <li>Engineering surveys (site surveys, profiling and cross-sectioning).</li>
 
                      </ul>
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 {/* <BuildingsCarousel></BuildingsCarousel> */}
                  <CivilEngineering></CivilEngineering>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services