import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Regional Offices | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
    
    <section className='section-cover bg-cover'>
       
        <div className='content-container '>
          
          <h2 className="home-h2">Regional Offices</h2>
          <div className='all-locations'>
            {/* <article className='location'>
                 <h3 className='title-h3 service-h3'>Corporate Headquarters</h3>
<p className='home-p home-p-b'>Bropesham Professional Engineering Contractors (U) Ltd
<br></br>Block 107, Plot 125 Kawanda, Bombo Rd, Wakiso District
<br></br>P. O. Box 26198 Kampala - Uganda
</p>
            </article> */}
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Regional Office</h3>
<p className='home-p home-p-b'>
    Bropesham Group of Companies S.A.R.L<br></br>
    Malemba Nkulu Avenue, No. 1, Socimat District, Gombe Municipality. Kinshasa City. DRC
</p>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Regional Office</h3>
<p className='home-p home-p-b'>
    Bropesham <br></br>
   N'Djamena City, Chad
</p>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Regional Office</h3>
<p className='home-p home-p-b'>
    Libreville City, Gabon
</p>
            </article>
             <article className='location'>
                 <h3 className='title-h3 service-h3'>Regional Office</h3>
<p className='home-p home-p-b'>
    Juba City, South Sudan
</p>
            </article>
             <article className='location'>
                 <h3 className='title-h3 service-h3'>Regional Office</h3>
<p className='home-p home-p-b'>
    Bangui City, Central African Republic (CAR)
</p>
            </article>

          </div>
        </div>   

    </section>
  
  

  

   
   
    
  
    </>
  )
}

export default Home