import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import Plumbing from '../components/plumbing';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Sole Supplier of Europa Prefabri Products | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sole Supplier of Europa Prefabri's Products</h3>
                      <p>
                        We also deal in prefabricated industrial homes, residential homes, etc., imported directly from Spain (European made). They use polystyrene blocks instead of concrete blocks on the walls which is cost effective compared to concrete blocks. These products have long lasting insulation property as they stay as permanent shuttering. More so, they are resistant to ageing, fire, vibration and rot. The material used is called extruded polystyrene.

                      </p>
                      
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <Plumbing></Plumbing>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services