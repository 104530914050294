import Carousel from 'react-bootstrap/Carousel';

function BuildingsCarousel() {
  return (
    <Carousel  indicators={false} className="goals-carousel buildings" >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/106.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/107.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/108.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/109.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/110.jpg"
          alt="First slide"
        />
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="First slide"
        />
      </Carousel.Item>  
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/37.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/46.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/47.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/48.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/49.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/50.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/52.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/53.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/54.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/55.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/56.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/57.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/58.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/59.jpg"
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default BuildingsCarousel;