import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Personnel from './pages/Projects';
import Projects from './pages/Projects';
import CoreValues from './pages/CoreValues';
import Water from './pages/Water';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Gallery from './pages/Equipment';
import Certification from './pages/Certification';
import BuildingConstruction from './pages/BuildingConstruction';
import CivilEngineering from './pages/CivilEngineering';
import Contact from './pages/Contact';
import Locations from './pages/Locations';
import Policies from './pages/Policies';
import Roads from './pages/RoadsEarthworks';
import EuropaPrefabri from './pages/EuropaPrefabri';
import Experience from './pages/CompletedProjects';
import Careers from './pages/Careers';
import Sewerage from './pages/Sewerage';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";
import WhatWeDo from "./pages/What-we-do";
import HowWeDoIt from "./pages/How-we-do-it";
import OHSP from "./pages/OHSP";
import FA from "./pages/FA";
import HR from "./pages/HR";
import PM from "./pages/PM";
import QS from "./pages/QS";
import QSHE from "./pages/QSHE";
import SE from "./pages/SE";
import SK from "./pages/SK";
import Surveyor from "./pages/Surveyor";
import EEP from "./pages/EqualEmployementPolicy";
import SD from "./pages/StaffDevelopment";


function App() {
  return (
    <BrowserRouter>
    <TopSection></TopSection>
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='who-we-are' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='pictorial' element={<Gallery></Gallery>}></Route>
            <Route path='core-values' element={<CoreValues></CoreValues>}></Route>
            <Route path='building-construction' element={<BuildingConstruction></BuildingConstruction>}></Route>
            <Route path='legal-status' element={<Certification></Certification>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
       <Route path='regional-offices' element={<Locations></Locations>}></Route>
       <Route path='policies' element={<Policies></Policies>}></Route>
       <Route path='roads-earthworks' element={<Roads></Roads>}></Route>
      <Route path='projects' element={<Projects></Projects>}></Route>
      <Route path='general-experience' element={<Experience></Experience>}></Route>
      <Route path='personnel' element={<Personnel></Personnel>}></Route>
      <Route path='civil-engineering' element={<CivilEngineering></CivilEngineering>}></Route> 
      <Route path='sewerage-sanitation-works' element={<Sewerage></Sewerage>}></Route>
      <Route path='water-environmental-engineering' element={<Water></Water>}></Route>           
      <Route path='europa-prefabri-materials' element={<EuropaPrefabri></EuropaPrefabri>}></Route> 
      <Route path='what-we-do' element={<WhatWeDo></WhatWeDo>}></Route>      
      <Route path='how-we-do-it' element={<HowWeDoIt></HowWeDoIt>}></Route> 
      <Route path='careers' element={<Careers></Careers>}></Route> 
      <Route path='ohsp' element={<OHSP></OHSP>}></Route>
      <Route path='financial-accountant' element={<FA></FA>}></Route>  
      <Route path='hr-officer' element={<HR></HR>}></Route> 
      <Route path='project-manager' element={<PM></PM>}></Route> 
      <Route path='quantity-surveyor' element={<QS></QS>}></Route>
      <Route path='site-qshe-officer' element={<QSHE></QSHE>}></Route>  
      <Route path='site-engineer' element={<SE></SE>}></Route>  
      <Route path='store-keeper' element={<SK></SK>}></Route>  
      <Route path='surveyor' element={<Surveyor></Surveyor>}></Route> 
      <Route path='equal-employment-policy' element={<EEP></EEP>}></Route> 
      <Route path='staff-development' element={<SD></SD>}></Route>     
       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
