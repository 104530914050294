import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "Core Values | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Core Values</h2>
      
          <div className='content-container vm-container'>
                 

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div>
          </div>
    </section>
    

    </>
  )
}

export default WhoWeAre