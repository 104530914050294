import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'General Experience | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">General Experience</h2>

         

           <table className='team-table'>
    
    <tr>
      {/* <th>Year</th> */}
      <th>Project/Client</th>
      <th>Description of Works</th>
      {/* <th>Value (Ugx)</th> */}
      <th>Status</th>
    </tr>

     <tr>
      {/* <td>2018 <br></br>Jan - July</td> */}
      <td>Save the Children<br></br>
Construction of 4 No.
Drainable latrines in
Sentema, Wakiso
District</td>
      <td>
        <ul>
       <li> Sub structure</li>
<li>Super structure</li>
<li> Roofing</li>
<li>Finishes</li>
</ul>
        </td>
      {/* <td>81,417,756/=</td> */}
      <td>Main Contractor <br></br>100% Complete
</td>
    </tr>
    <tr>
        {/* <td>
            2018<br></br>
March
        </td> */}
        <td>
            U.E.T.C.L<br></br>
Supply of marrum for
Kapeeka Temporary
sub-station area and
Access road
        </td>
        <td>
            <ul>
                <li>Earthworks</li> 
<li>Delivery of marrum</li>
            </ul>
        </td>
        {/* <td>
            105,095,520/=
            </td> */}
        <td>
            Main Contractor<br></br>
100% Complete

        </td>
    </tr>
    <tr>
        {/* <td>
            2018<br></br>
Jan to date 
        </td> */}
        <td>
            Kyenjojo District Local
Government<br></br>
Construction of
Kanyengaramire Water
Supply System 
        </td>
        <td>
            <ul>
               <li>Drilling of Borehole</li>
<li>Construction of
Control Room</li>
<li>Laying of
transmission line</li>
<li>Construction of
Reservoir Tank</li>
            </ul>
        </td>
        {/* <td>
            214,220,339/=
        </td> */}
        <td>
            Main Contractor<br></br>
85% Complete

        </td>

    </tr>
<tr>
    {/* <td>
        2018<br></br>
Jan - May
    </td> */}
    <td>
        Kyenjojo District Local
Government<br></br>
Construction of a Two
Classroom Block and
Office and Furniture
    </td>
    <td>
        <ul>
    <li>Sub structure</li>
<li>Super structure</li>
<li>Roofing</li>
<li>Finishes</li>

        </ul>
    </td>
    {/* <td>
        68,640,252/=
    </td> */}
    <td>
        Main Contractor<br></br>
100% Completed
    </td>
</tr>
<tr>
    {/* <td>
        2017<br></br>
December
2017
    </td> */}
    <td>
        Save the Children<br></br>
Construction of 2 No.
Drainable latrines at St.
Kizito P/S Bembe,
Wakiso District
    </td>
    <td>
        <ul>
<li>Sub structure</li>
<li>Super structure</li>
<li>Roofing</li>
<li>Finishes</li>
</ul>
    </td>
    {/* <td>
     
23,584,503/=
    </td> */}
    <td>
        Main Contractor<br></br>
100% Completed 
    </td>
</tr>
<tr>
    {/* <td>
        2017<br></br>
August
2018
    </td> */}
    <td>
        Makerere University<br></br>
Construction of Feed
Production Facility at
Nakyesasa Incubation
Centre, Wakiso District
    </td>
    <td>
        <ul>
          <li> Sub structure</li>  
<li> Super structure</li>
<li> Roofing</li>
<li> Finishes</li>
<li> Electrical Works</li>
        </ul>
    </td>
    {/* <td>199,968,200/=</td> */}
    <td>
        Main Contractor<br></br>
100% Complete
    </td>
</tr>
<tr>
    {/* <td>
        2017<br></br>
August
2018
    </td> */}
    <td>
        O.T.V Uganda<br></br>
Modifying Workshop
into Control Room and
Offices at Bugolobi
Sewage Plant
    </td>
    <td>
        <ul>
           <li>Demolitions</li> 
<li>Civil Works</li>
<li>Aluminum Works</li>
<li>Electrical Works</li>
<li>Drainage Works</li>
        </ul>
    </td>
    {/* <td>
        458,650,465/=
    </td> */}
    <td>
        Sub - Contractor<br></br>
100% Completed 
    </td>
</tr>
<tr>
    {/* <td>
        2016 
    </td> */}
    <td>
        Kyenjojo District Local
Government<br></br>
Construction of a Two
Classroom Block and
Office and Store at
Kyakahiirwa Primary
School
    </td>
    <td>
        <ul>
    <li>Sub structure</li>
<li>Super structure</li>
<li> Roofing</li>
<li>Finishes</li>

        </ul>
    </td>
    {/* <td>
        61,105,200/=
    </td> */}
    <td>
        Main Contractor
<br></br>
Completed June
2018

    </td>

</tr>
<tr>
    {/* <td>
        2016 
    </td> */}
    <td>
        Nansana Municipality<br></br>
Construction of Two
storied Classroom
Block at Sibba Primary
School - Nakyesanja
    </td>
    <td>
        <ul>
<li>Sub structure</li> 
<li>Super structure</li> 
<li> Roofing</li> 
<li>Finishes</li> 
   </ul>
    </td>
    {/* <td>
        605,530,500/=
    </td> */}
    <td>
        Main Contractor<br></br>
Completed March
2017

    </td>
</tr>
<tr>
    {/* <td>
        2016
    </td> */}
    <td>
        S & J Properties
Construction of
Residential Apartments
in Kampala
    </td>
    <td>
        <ul>
        <li>Sub structure</li>
<li>Super structure</li>
<li>Roofing</li>
<li>Finishes</li>
<li>Electrical
Works</li>

        </ul>
    </td>
    {/* <td>
        810,255,000

    </td> */}
    <td>
        Main Contractor
<br></br>
Completed July
2016
    </td>

</tr>
<tr>
    {/* <td>
        2015
    </td> */}
    <td>
        GT20 Engineering Co.
Ltd
P.O. Box, 2428
Kampala<br></br>
Construction of a
Single Storied HIV
Clinic for Health
Initiatives Association
in Buikwe District
    </td>
    <td>
        <ul>
<li>Sub structure</li> 
<li>Super structure</li> 
<li>Roofing</li> 
<li>Finishes</li> 
<li>Electrical Works</li> 

        </ul>
    </td>
    {/* <td>
    250,555,400/=
</td> */}
<td>
    Sub - Contractor<br></br>
 Completed Feb
2016
</td>
</tr>


<tr>
    {/* <td>
        Oct 2014
– Feb
2015
    </td> */}
    <td>
        KCCA
Construction of a Three
storied Classroom
Block at Kamwokya
KCCA Primary School
    </td>
    <td>
        <ul>
             <li>Sub structure</li>
<li> 1<sup>st</sup>, 2<sup>nd</sup> & 3<sup>rd</sup> floors</li>
<li> Roofing</li>
<li>Finishes</li>
<li>Electrical Works</li>

        </ul>
    </td>
    {/* <td></td> */}
    <td>Sub - Contractor
<br></br>
Complete</td>
</tr>
<tr>
    {/* <td>
        2015<br></br>
May to
August
    </td> */}
    <td>
        Roko
Construction<br></br>
Roscoe Road
Apartments,Kololo

    </td>
    <td>
        <ul>
        <li>Super structure
brickwork</li>
<li>Finishes ( Facing
Bricks)</li>
        </ul>
    </td>
    {/* <td>
        60,809.56 USD
    </td> */}
    <td>
        Sub - Contractor
<br></br>
Completed
    </td>
</tr>
<tr>
    {/* <td>
        Sept 2014
to Feb
2015

    </td> */}
    <td>
        M/S Nandegeya
Gertrude<br></br>
Construction of a
Single storied
Residential Block at
Lweza, Entebbe Road.
    </td>
    <td>
        <ul>
        <li>  Sub structure</li> 
<li>  Super structure</li>
<li>   Roofing</li>
<li>  Finishes</li>
<li>  Electrical works</li>
        </ul>
    </td>
    {/* <td>
    100,000,000/=
</td> */}
<td>
    Sub – Contractor<br></br>
 100% Complete
</td>
</tr>
<tr>
    {/* <td>
        Aug 2013
to Jan
2014

    </td> */}
    <td>Green Label services
Ltd<br></br>
Construction of Office
Block</td>
<td>
    <ul>
 <li>  Lower Basement</li> 
<li> Upper Basement</li>
<li> 1<sup>st</sup>,2<sup>nd</sup> & 3<sup>rd</sup>
floors</li>
<li>Roofing</li>
<li>Finishes</li>
<li> Electrical</li>
</ul>
</td>
{/* <td>

</td> */}
<td>
    Sub - Contractor
<br></br>
100% Complete

</td>

</tr>
<tr>
    {/* <td>
        October
2013 to
Jan 2014
    </td> */}
    <td>
        MAAIF<br></br>
Renovation of Hatchery
and Construction of
Boundary wall at
Entebbe Livestock
    </td>
    <td>
        <ul>
    <li>Demolitions and
general repairs</li>
<li>Boundary wall
construction</li>
<li>Finishes</li>

        </ul>
    </td>
    {/* <td>
        350,000,000/=
    </td> */}
    <td>
        Sub - Contractor<br></br>
100% Complete
    </td>
</tr>
  <tr>
    {/* <td>
        2013
    </td> */}
    <td>
        M/S Heritage Café Pape<br></br>
Renovation of Ihamba
Safari Lodge, Kasese
    </td>
    <td>
        <ul>
<li>Demolitions and
general repairs</li>
<li>Paint works and
face lifting of
structures </li>
        </ul>
    </td>
    {/* <td>
        398,000,000/= 
    </td> */}
    <td>
        Sub - Contractor<br></br>
 100% Complete
    </td>
  </tr>
  <tr>
    {/* <td>
        2012
    </td> */}
    <td>
        Mr. Kakyope Ivan D<br></br>
Construction of
Dormitory Block,
Teachers’ House and
Main Hall at Mt. Zeon
S.S Kalonga, Mubende
    </td>
    <td>
        <ul>
             <li>Sub structure</li>
<li>Super structure</li>
<li> Roofing</li>
<li>Finishes</li>
<li>Electrical Works</li>
        </ul>
    </td>
    {/* <td>
        275,465,000/=
    </td> */}
    <td>
        Sub - Contractor<br></br>
 100% Complete
    </td>



  </tr>

  


  </table>
</div>
           
    </section>
  )
}

export default Team