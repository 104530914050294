import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
     
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'>
                <strong>Bropesham Professional Engineering Contractors Limited (BPECL)</strong> was founded in 2012 by a
group of Ugandans as a limited liability Company and has brought together a number of
professionals who have had extensive local and international experiences with reputable
international engineering practices spanning over a period of over a decade. We have over {2023 - 2013} years of experience in Uganda and Democratic Republic of Congo. 
BPECL offers a comprehensive range of engineering construction services within a
number of disciplines (civil, mechanical, electrical, oil & gas, environmental, public
health, etc) to its growing clients locally, regionally and internationally. These services
are backed by a core of professionally competent, experienced and dedicated team, with
a wide range of skills and access to the latest technology. We have more than 300 Employees and we have more than five projects underway.

</p>
<p className='home-p'>
  BPECL has positioned itself in the construction industry with clear strategy of meeting
the constantly growing demands of various clients in both the private sector and public
sector locally, regional and international with maximum flexibility, and with expert
knowledge of engineering construction and associated environmental issues which are
of vital importance in the engineering field.
  </p>


 
        </div>

    </section>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.82), rgba(8, 6, 4, 0.91)), url("./images/21.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Aim</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To be the recognized market leader in provision of Medical Equipment, Laboratory Supplies, installation, user training and after Sales Services within the region.
</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}
<section className='new-home-cta'>
  <div className='new-home-cta-container'>
      <Link to='what-we-do' className='home-link' onClick={scrollFunc}>What We Do</Link>
       <Link to='how-we-do-it' className='home-link' onClick={scrollFunc}>How We Do It</Link>
  </div>
</section>
  
  <section className="categories">
    <h2 className="home-h2">Our Services</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>
                 <div className="categories-container">
                  <article className="category">
                             <div className="category-image">
                                    <img src="./images/107.jpg" alt="Image" className="category-photo"/>
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3">Roads & Earthworks</h3> 
                                    {/* <p className="category-p">Quality Office Furniture at affordable prices.</p>    */}
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    <Link to='roads-earthworks' className="category-link" onClick={scrollFunc}>See Details</Link>

                            </div>
                           </article>
                           <article className="category">
                             <div className="category-image">
                                    <img src="./images/121.jpg" alt="Image" className="category-photo"/>
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3">Civil Engineering</h3> 
                                    {/* <p className="category-p">Quality Office Furniture at affordable prices.</p>    */}
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    <Link to='civil-engineering' className="category-link" onClick={scrollFunc}>See Details</Link>

                            </div>
                           </article>
                           <article className="category">
                             <div className="category-image">
                                    <img src="./images/25.jpg" alt="Image" className="category-photo"/>
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3">Building Construction</h3> 
                                    {/* <p className="category-p">Quality Office Furniture at affordable prices.</p>    */}
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    <Link to='building-construction' className="category-link" onClick={scrollFunc}>See Details</Link>

                            </div>


                           </article>

                           <article className="category">
                            <div className="category-image">
                                   <img src="./images/26.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">Water & Environmental Engineering</h3> 
                                   {/* <p className="category-p">Beautiful and classy Home Furniture at affordable prices.</p>    */}
                                   
                                   <Link to='water-environmental-engineering' className="category-link" onClick={scrollFunc}>See Details</Link>

                           </div>


                          </article>

                          <article className="category">
                            <div className="category-image">
                                   <img src="./images/27.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">Sewerage & Sanitation Works
</h3> 
                                   {/* <p className="category-p">Quality Curtains and Blinds at affordable prices.</p>    */}
                                   
                                   <Link to='sewerage-sanitation-works' className="category-link" onClick={scrollFunc}>See Details</Link>

                           </div>
                          </article>
                          <article className="category">
                            <div className="category-image">
                                   <img src="./images/7a.jpg" alt="Image" className="category-photo"/>
                            </div>


                           
                            <div className="category-content">
                                   <h3 className="category-h3">Sole Supplier of Europa Prefabri's Products
</h3> 
                                   {/* <p className="category-p">Quality Curtains and Blinds at affordable prices.</p>    */}
                                   
                                   <Link to='europa-prefabri-materials' className="category-link" onClick={scrollFunc}>See Details</Link>

                           </div>


                          </article>

                 </div>
        </section>

        <section className="categories partners-cover">
    <h2 className="home-h2">Our Partners</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>
                 <div className='partners'>
                      <img src='./images/62.png' className='partner-logo'></img>
                 {/* <img src='./images/63.png' className='partner-logo'></img> */}
                 </div>
        </section>
   
   
    
  
    </>
  )
}

export default Home