import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin,FaMobileAlt,FaPhoneAlt} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.82), rgba(8, 6, 4, 0.91)), url("./images/12.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='/' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>
                        {/* <div className='footer-link'>
                              <Link to='legal-status' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Legal Status</p></Link> 
                        </div> */}
                        
                        <div className='footer-link'>
                              <Link to='who-we-are' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Who We Are</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='policies' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Policies</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='regional-offices' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Regional Offices</p></Link> 
                        </div>
                        
                       <div className='footer-link'>
                              <Link to='core-values' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Core Values</p></Link> 
                        </div>
                        

                        <div className='footer-link'>
                              <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='careers' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Careers</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='clients' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Clients</p></Link> 
                        </div>
                         
                     {/* <div className='footer-link'>
                              <Link to='pictorial' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Pictorial</p></Link> 
                        </div> */}
                        
                        {/* <div className='footer-link'>
                              <Link to='contact' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Write To Us</p></Link> 
                        </div> */}

                        
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Corporate Headquarters</h3>
                      <p>
                     Plot 125, Block 107, Bombo Road, Kawanda 
                     <br></br>
                     P. O. Box 26198, Kampala - Uganda

                        </p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                      <FaPhoneAlt className="top-icon"></FaPhoneAlt> +256 414 698396
                      <br></br>
                   <FaMobileAlt className='top-icon'></FaMobileAlt> +256 752 822881
                      </p>

                    </div>

                   
                      

             </div>

          <div className='footer-inner-content'>


                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  <p>info@bropesham.com<br></br>
                   bropesham@gmail.com
                   </p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Bropesham Professional Engineering Contractors Ltd (BPECL) | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer