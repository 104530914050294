import Carousel from 'react-bootstrap/Carousel';

function MaintCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/61.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/98.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/99.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/101.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/102.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/103.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default MaintCarousel;