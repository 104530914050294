import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Staff Development | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
    
    <section className='section-cover bg-cover'>
       
        <div className='content-container '>
          
          {/* <h2 className="home-h2">Our Policies</h2> */}
        
          <article className='policies-container'>
             <div className='policy-text'>
                <h3>Staff Development</h3>
<p className='home-p home-p-a'>
  <strong>BPECL</strong> is committed to ensuring that its employees are given opportunity for development through training that is relevant to <strong>BPECL</strong> work. Such training may be fully or partly sponsored by <strong>BPECL</strong>, depending on the need for the training and availability of funds, Employees are expected to make full use of the resources provided by <strong>BPECL</strong> to improve their professional and technical expertise.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/81.jpg'></img>
             </div>

          </article>
        
        </div>   

    </section>
    </>
  )
}

export default Home