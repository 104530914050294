import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "How We Do It | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
  
   <section className='home-service-cover'> 
    <div className='home-services-container '>
  
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCog></FaCog></span>
          </div>
          <h3 className='home-service-title'>How We Do It</h3>
          <p>
           BPECL relies heavily on its human resources to deliver quality construction and to
access other resources which are not in their stock. Electrical, Mechanical, civil
materials/resources are usually sourced locally using both cash and credit
arrangements.
            </p>
            <p>
           BPECL strive to beat all our deadlines through the dedicated teams that execute their
duties under tight schedules. Most of our projects are accomplished within the project
timeframe and this places smiles on the faces of our clients.
            </p>
        </div>    
    </div>
        </div>
    </div>
  </section>
    </>
  )
}

export default Home