import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import BuildingsConstruction from '../components/BuildingsConstruction'
import Plumbing from '../components/plumbing';
import CivilEngineering from '../components/CivilEngineering';
import RoadsEarthworks from '../components/RoadsEarthworks';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Services | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
            
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Roads & Earthworks</h3>
                     <Link to='/roads-earthworks' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>
                    
                  </article>

                  <article className='service-slides'>
                    <RoadsEarthworks></RoadsEarthworks>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Civil Engineering</h3>
            <Link to='/civil-engineering' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>
                     {/* <p>
    We have brought you both new and refurbished medical equipment to your doorsteps in Uganda. We understand the convergence point between high-quality medical equipment & affordability. You'll find both at ELK'S Medicals Limited.
                   
                     </p> */}
                  </article>

                  <article className='service-slides'>
                     <CivilEngineering></CivilEngineering>
                 
                  </article>     
            </div>
              <div className='service-container'>
                   <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Building Construction</h3>
                     <Link to='/building-construction' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>
                    
                  </article>

                  <article className='service-slides'>
                      <BuildingsConstruction></BuildingsConstruction>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Water & Environmental Engineering</h3>
                     <Link to='/water-environmental-engineering' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>
                    
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sewerage & Sanitation Works</h3>
                     <Link to='/sewerage-sanitation-works' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>  
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sole Supplier of Europa Prefabri's Products</h3>
                     <Link to='/europa-prefabri-materials' className="category-link category-link1" onClick={scrollFunc}>See Details</Link>
                    
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div>

           </div>

    </section>
  )
}

export default Services