import Carousel from 'react-bootstrap/Carousel';

function GoalsCarousel() {
  return (
    <Carousel  indicators={false} className="goals-carousel" >
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/54.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/55.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/56.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/71.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/72.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/73.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      
      
    </Carousel>
  );
}

export default GoalsCarousel;