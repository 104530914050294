import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "What We Do | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
   <section className='home-service-cover'>
   
   
    <div className='home-services-container '>
  
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><MdEngineering></MdEngineering></span>
          </div>
          <h3 className='home-service-title'>What We Do 
</h3>
          <p>
            BPECL provides all civil and building construction services that range from designsconstruction, remodelling and refurbishment of structures and Electro-mechanical works.
We have provided these quality services for over ten (10) years with directors at the
forefront before incorporation.

            </p>
            <p>
            Driven by the passion to develop Africa, BPECL core principle is value for money with
team work at the centre of its service provision. BPECL is dedicated to high quality
constructions which are delivered within the time frame.
            </p>

        </div>
      
    </div>
      


        </div>


    </div>



  </section>

  
    </>
  )
}

export default Home