import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Careers | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
     
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Careers</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'>
                <strong>BPECL</strong> seeks to employ a team of dedicated, passionate and experienced personel to fill up the following positions in their new project sites in Uganda and the DR Congo. Soft copy application should be sent via email to <strong>hr@bropesham.com</strong> comprising of a detailed CV with 3 professional references, academic documents and any other relevant documents not later than the 24th August 2023, 5pm EAT.

</p>
 <div className='all-locations'>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Financial Accountant - 1 Position
</h3>
<Link to='/financial-accountant' className='job-link'>See Advert</Link>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>HR Officer - 2 Positions (1 Kampala, 1 Kinshasa)
</h3>
<Link to='/hr-officer' className='job-link'>See Advert</Link>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Project Manager - 2 Positions (1 Kampala, 1 Kinshasa)
</h3>
<Link to='/project-manager' className='job-link'>See Advert</Link>
            </article>
             <article className='location'>
                 <h3 className='title-h3 service-h3'> Quantity Surveyor - 3 Positions (1 Kampala, 2 Kinshasa)
</h3>
<Link to='/quantity-surveyor' className='job-link'>See Advert</Link>
            </article>
             <article className='location'>
                 <h3 className='title-h3 service-h3'>Site QSHE Officer - 4 Positions (1 Kampala, 3 Kinshasa)</h3>
<Link to='/site-qshe-officer' className='job-link'>See Advert</Link>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Site Engineer - 2 Positions (2 Kampala, 3 Kinshasa)
</h3>
<Link to='/site-engineer' className='job-link'>See Advert</Link>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Store Keeper - 6 Positions (2 Kampala, 4 Kinshasa)
</h3>
<Link to='/store-keeper' className='job-link'>See Advert</Link>
            </article>
            <article className='location'>
                 <h3 className='title-h3 service-h3'>Surveyor - 2 Positions (1 Kampala, 1 Kinshasa)
</h3>
<Link to='/surveyor' className='job-link'>See Advert</Link>
            </article>

          </div>



 
        </div>

    </section>
 
    </>
  )
}

export default Home