import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/11.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/46.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
    
       
      
    </Carousel>
  );
}

export default PowerCarousel;