import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'
import BuildingsConstruction from '../components/BuildingsConstruction'
import RoadsEarthworks from '../components/RoadsEarthworks';
const Services = () => {

useEffect(()=>{
document.title = 'Roads & Earthworks | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Roads & Earthworks</h3>
                      {/* <p>
                        Expertise covered under civil and structural engineering construction includes, Geotechnical investigations, Design and construction, Electro-mechanical works. Specific
areas where BPECL has extensive experiences are presented below:
                      </p> */}
                      <ul>
                        
 
 <li> Roads construction including associated drainage works, structures and furniture.</li>
 <li>General earthworks for major engineering projects.</li>

 
                      </ul>
                  </article>

                  <article className='service-slides'>
                  <RoadsEarthworks></RoadsEarthworks>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services