import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'
import BuildingsConstruction from '../components/BuildingsConstruction'
const Services = () => {

useEffect(()=>{
document.title = 'Building Construction | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Building Construction</h3>
                      {/* <p>
                        Expertise covered under civil and structural engineering construction includes, Geotechnical investigations, Design and construction, Electro-mechanical works. Specific
areas where BPECL has extensive experiences are presented below:
                      </p> */}
                      <ul>
                        
 
 <li> High-rise buildings
 (structural steel framed structures and
 reinforced concrete structures)</li>
 <li>Industrial
 plants (warehouses, factories etc).</li>
 <li>Institutional buildings (hospitals, schools,
 markets, etc).</li>
 
                      </ul>
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 {/* <BuildingsCarousel></BuildingsCarousel> */}
                  <BuildingsConstruction></BuildingsConstruction>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services