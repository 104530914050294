import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Site Engineer | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
    
    <section className='section-cover bg-cover'>
       
        <div className='content-container'>
          
         <object data="./docs/SE.pdf" type="application/pdf" className='ohsp' >
      {/* <p>Unable to display PDF file. <a href="/docs/OHSP.pdf">Download</a> instead.</p> */}
    </object>

        </div>   

    </section>
    </>
  )
}

export default Home