import Carousel from 'react-bootstrap/Carousel';

function MissionCarousel() {
  return (
    <Carousel  indicators={false} className='mission-slides'>
     
      
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/45.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/46.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/71.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/72.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/73.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/93.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/107.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      
    </Carousel>
  );
}

export default MissionCarousel;