import React from 'react'

let ValuesData = [

{title: "Integrity",      
content: "Bropesham is committed to observe absolute honesty and integrity in all their business undertakings."
},

{title: "Honouring Commitment",      
content: "Bropesham and or its businesses will at all times Provides Expertise standards and maintain good name of the company by honouring its commitments to Shareholders, Management, Employees, Customers/Clients, on time and within budgeted cost deliveries."
},
{title: "Professionalism",      
content: "Bropesham is a proactive professional, Self-reliant, you are guided by entrepreneurship and moral, ethical standards.  Bropesham will at all times, maintain high standards of accuracy in advice and information given to the Shareholders, Management, Employees, Customers/Clients in the fields for which they are responsible."
},
{title: "Commitment to Safety & Health",      
content: "Safety is our goal. Bropesham is committed to pursue in the course of business, sound and responsible occupational Health & Safety practices to ensure safety of our employees, Clients/Customers."
},
{title: "Care for the Environment",      
content: "Our environment is our Pride. Bropesham is committed to pursue in the course of business, sound and responsible practices to ensure the protection of the Environment are adhered to at all times throughout all their activities."
},
{
title:"Team Spirit",
content:"We are all together with the same mission and goal. Our employees work together in a spirit of sharing to disseminate good practices. We can mobilize a full range of expert skill sets to provide our clients with comprehensive solutions."
}
];

export default ValuesData;