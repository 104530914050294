import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Water & Environmental Engineering | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Water & Environmental Engineering</h3>
                      <p>
                        Expertise covered under water and Environmental engineering covers; construction of all related water
resources structures, and construction of environmental management structures such as waste disposal
facilities, public health and sanitation facilities, and conservation of environment. Specific areas are
listed below under which the expertise are applicable:

                      </p>
                      <ul>
                      <li>Construction of water treatment
 works facilities (civil & electro-
 mechanical).</li>
 <li>Construction of water
 transmission facilities (pumping
 mains, gravity flow mains,
 pumping stations, booster
 stations).</li>
 <li>Construction of water distribution
 facilities (Storage reservoirs,
 service connections, water sale
 points).</li>
<li>Construction of water production
 facilities for both surface &
 ground water abstraction (intake
 structures).</li>
<li>Construction of water resources
 facilities (Deep wells, Springs,
 Infiltration galleries, River
 intakes and lake intakes).</li>
<li>Engineering surveys (Site
 surveys, profiling and cross-sectioning).</li>
                        
                      </ul>
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <EmergencyCarousel></EmergencyCarousel>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services