import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Our Clients | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
          {/* <p className='clients-para'></p> */}
        <div className='content-container clients'>

           

            <article className='client'>
                      <img src='./images/104.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/105.jpg' alt='client' className='client-image'></img>
           </article>
              <article className='client'>
                      <img src='./images/111.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/112.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/113.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/114.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/115.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/116.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/117.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/119.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/125.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/126.png' alt='client' className='client-image'></img>
           </article>

      
          </div> 
    </section>
  )
}

export default Clients