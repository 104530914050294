import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Sewerage & Sanitation Works | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sewerage & Sanitation Works</h3>
                      <p>
                        Expertise covered under sewerage and sanitation
system engineering and construction
services covers, Construction of onsite and offsite
disposal facilities, collection facilities,
treatment facilities, disposal facilities. Specific areas
are listed below under which the
expertise are applicable.

                      </p>
                      
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <MaintCarousel></MaintCarousel>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services