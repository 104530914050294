import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Equal Employment Policy | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
    
    <section className='section-cover bg-cover'>
       
        <div className='content-container '>
          
          {/* <h2 className="home-h2">Our Policies</h2> */}
        
          <article className='policies-container'>
             <div className='policy-text'>
                <h3>Equal Employment Policy</h3>
<p className='home-p home-p-a'>
   <strong>BPECL</strong> is committed to a policy of providing equal opportunities in recruiting and selecting applicants for employments, in advancing personnel as suitable openings develop, and in any matter affecting their employment.  <strong>BPECL</strong> will provide equal opportunities without regard to sex, race, color, religion, national origin, handicap, political opinion, social origin or marital status.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/127.jpg'></img>
             </div>

          </article>
        
        </div>   

    </section>
    </>
  )
}

export default Home