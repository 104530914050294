import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/53.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/85.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/89.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/90.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/91.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/92.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/93.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      
       
      
    </Carousel>
  );
}

export default PowerCarousel;