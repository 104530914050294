import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          // src="./images/1.jpg"
          //  src="./images/89.jpg"
           src="./images/93.jpg"
          //  src="./images/85.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3 className="slide-h3">Bropesham Professional Engineering Contractors Ltd (BPECL)</h3> */}
          <h3 className="slide-h3">Professional Engineering Services</h3>
          {/* <p className="slide-p slide-p1">"Always Safe"</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/2.jpg"
          //  src="./images/2.jpg"
              src="./images/25.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p">
           Providing Civil & Building Construction Services
            </p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100 road-slide"
          // src="./images/7.jpg"
           src="./images/106.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
          We do Road Construction & Maintenance
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/87.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
         We are prominent in Electro-Mechanical Works
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/60.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
        Trust us for Water & Environmental Engineering Solutions
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
       We also provide Sewerage & Sanitation Services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>We Plan, Schedule, Execute & Control Projects
          </h3>
          <p className="slide-p">
           We also provide air conditioning services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item> */}
    </Carousel>
  );
}

export default CarouselFade;