import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
import {MdEngineering} from 'react-icons/md';
// import {FcEngineering} from 'react-icons/fc';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Our Policies | Bropesham Professional Engineering Contractors Ltd (BPECL)";

},[]);

  return (
    <>
    
    <section className='section-cover bg-cover'>
       
        <div className='content-container '>
          
          <h2 className="home-h2">Our Policies</h2>
          
          <article className='policies-container policies-container1'>
             <div className='policy-text'>
                <h3>Occupational Health & Safety Policy</h3>
                <Link to='/ohsp' className='policy-link'>See Details</Link>
{/* <p className='home-p home-p-a'>
    We ensure the Health and Safety of our clients, employees and communities within which we work, using structured systems, processes and procedures.
  </p> */}

             </div>
              <div className='policy-image'>
                <img src='./images/119.jpg'></img>
             </div>

          </article>
          <article className='policies-container'>
             <div className='policy-text'>
                <h3>Quality Policy</h3>
<p className='home-p home-p-a'>
    "We do right the first time and every time" providing value for money while using quality materials. We consistently satisfy requirements as expected as we provide services that meet or exceed the expectations of our clients, regulators and staff in a professional, ethically and responsible manner.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/118.png'></img>
             </div>

          </article>
          
          
          <article className='policies-container policies-container1'>
             <div className='policy-text'>
                <h3>Environmental Policy</h3>
<p className='home-p home-p-a'>
   We strive to maintain and improve the level of environmental management by strictly complying with environment-related laws, ordinances and agreements in all aspects of our activities.
  </p>

             </div>
              <div className='policy-image'>
               <img src='./images/120.jpg'></img>
             </div>

          </article>
          {/* <article className='policies-container'>
             <div className='policy-text'>
                <h3>Environmental Policy</h3>
<p className='home-p home-p-a'>
   We strive to maintain and improve the level of environmental management by strictly complying with environment-related laws, ordinances and agreements in all aspects of our activities.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/120.jpg'></img>
             </div>

          </article> */}
          

        </div>   

    </section>
  
  

  

   
   
    
  
    </>
  )
}

export default Home