import Carousel from 'react-bootstrap/Carousel';

function EmergencyCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/60.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
 <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/94.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/95.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/96.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/97.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/100.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      

    </Carousel>
  );
}

export default EmergencyCarousel;