import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Personnel | Bropesham Professional Engineering Contractors Ltd (BPECL)';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Personnel</h2>

         

           <table className='team-table'>
    
    <tr>
      <th>S/No.</th>
      <th>Name</th>
      <th>Expertise</th>
      <th>Years' Experience</th>
      <th>Qualification</th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Ocen Samson </td>
      <td>Project Management</td>
      <td>More than {2023 - 2005}</td>
      <td>MEng.CM, MUIPE</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Twesigye Henry  </td>
      <td>Land Surveying</td>
      <td>More than {2023 - 2017}</td>
      <td>ODCE</td>
    </tr>
    <tr>
      <td>3.</td>
      <td>Wokorac Stephen </td>
      <td>General</td>
      <td>More than {2023 - 2018}</td>
      <td>Bsc. CE</td>
    </tr>
    <tr>
      <td>4.</td>
      <td>Ogwang Nickson</td>
      <td>General</td>
      <td>More than {2023 - 2018}</td>
      <td>Bsc. CE</td>
    </tr>
    <tr>
      <td>5.</td>
      <td>Kavuma Allan</td>
      <td>General</td>
      <td>More than {2023 - 2013}</td>
      <td>Bsc. CE</td>
    </tr>
     <tr>
      <td>6.</td>
      <td>Mubiru Edward </td>
      <td>General</td>
      <td>More than {2023 - 2008}</td>
      <td>ODCE</td>
    </tr>
    <tr>
      <td>7.</td>
      <td>Okwany Godfrey</td>
      <td>Carpentry</td>
      <td>More than {2023 - 2009}</td>
      <td>ODCE</td>
    </tr>
     <tr>
      <td>8.</td>
      <td>Okello Cyrus</td>
      <td>Carpentry</td>
      <td>More than {2023 - 2014}</td>
      <td>ODCE</td>
    </tr>

  </table>
</div>
           
    </section>
  )
}

export default Team